import axios from "axios";
import { store } from "../store";
import { api } from "../../api";
import { LOGIN, LOGOUT } from "../types";

//login the user
export const authLogin =
  (payload, formik, history, enqueueSnackbar, setDisable) =>
  async (dispatch) => {
    await api({
      url: "/login",
      method: "post",
      data: { ...payload },
    })
      .then((res) => {
        localStorage.setItem("token", JSON.stringify(res.data.token));
        localStorage.setItem("user", JSON.stringify(res.data.user));
        dispatch({
          type: LOGIN,
          payload: res.data.user,
        });
        setDisable(false);
        history.push("/welcome");
      })
      .catch((err) => {
        setDisable(false);
        if (enqueueSnackbar) {
          enqueueSnackbar(
            err?.response?.data?.error || "some thing went wrong",
            { variant: "error" }
          );
        }
      });
  };
export const userLogout = (history) => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
  localStorage.clear();
  history.push("/login");
};
export const setUserVal = (payload, history) => async (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  dispatch({
    type: LOGIN,
    payload: userData,
  });
};
