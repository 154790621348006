import React, { useState } from "react";
export const SvgContainer = ({
  item,
  size = false,
  style,
  showDefault = false,
}) => {
  const svgItems = {
    //service desk priority column icons start
    user: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size ? size : 40}`}
        height={`${size ? size : 40}`}
        viewBox="0 0 18 18"
      >
        <defs>
          <linearGradient
            id="a"
            x1="9"
            y1="6.88"
            x2="9"
            y2="20.45"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".22" stopColor="#32d4f5" />
            <stop offset="1" stopColor="#198ab3" />
          </linearGradient>
          <linearGradient
            id="b"
            x1="8.61"
            y1="-.4"
            x2="9.6"
            y2="11.92"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".22" stopColor="#32d4f5" />
            <stop offset="1" stopColor="#198ab3" />
          </linearGradient>
        </defs>
        <path
          d="M15.72 18a1.45 1.45 0 001.45-1.45.47.47 0 000-.17C16.59 11.81 14 8.09 9 8.09s-7.66 3.15-8.17 8.3A1.46 1.46 0 002.14 18h13.58z"
          fill="url(#a)"
        />
        <path
          d="M9 9.17a4.59 4.59 0 01-2.48-.73L9 14.86l2.44-6.38A4.53 4.53 0 019 9.17z"
          fill="#fff"
          opacity=".8"
        />
        <circle cx="9.01" cy="4.58" r="4.58" fill="url(#b)" />
      </svg>
    ),
    signout: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size ? size : 40}`}
        height={`${size ? size : 40}`}
        viewBox="0 0 18 18"
      >
        <defs>
          <linearGradient
            id="a"
            x1="9.03"
            y1="13.38"
            x2="9.03"
            y2="4.63"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#198ab3" />
            <stop offset=".4" stopColor="#25aed3" />
            <stop offset=".78" stopColor="#2fcaec" />
            <stop offset="1" stopColor="#32d4f5" />
          </linearGradient>
        </defs>
        <rect
          x="4.65"
          y="4.63"
          width="8.75"
          height="8.75"
          rx=".41"
          fill="url(#a)"
        />
        <path
          d="M11.78 12.18l4.38-3.07a.13.13 0 000-.22l-4.38-3.06a.13.13 0 00-.21.11V7.7H4.65v2.6h6.92v1.77a.14.14 0 00.21.11z"
          fill="rgb(148, 148, 148)"
        />
        <path
          d="M17 17.6H1a.52.52 0 01-.52-.52V.92A.52.52 0 011 .4h16a.52.52 0 01.52.52V6a.26.26 0 01-.41.22l-1-.7A.27.27 0 0116 5.3V2H2.06v14h13.88v-3.2a.26.26 0 01.11-.21l1-.73a.26.26 0 01.41.22v5a.52.52 0 01-.46.52z"
          fill="#949494"
        />
        <path
          d="M15.94 2v3.25a.27.27 0 00.11.22l1 .7A.26.26 0 0017.5 6V2zM15.94 16v-3.25a.25.25 0 01.11-.21l1-.71a.27.27 0 01.41.22v4z"
          fill="#767676"
        />
      </svg>
    ),
    printer: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        width={`${size ? size : 40}`}
        height={`${size ? size : 40}`}
        version="1.1"
        id="Layer_1"
        viewBox="0 0 64 64"
        enable-background="new 0 0 64 64"
      >
        <g id="Printer">
          <path d="M57.7881012,14.03125H52.5v-8.0625c0-2.2091999-1.7909012-4-4-4h-33c-2.2091999,0-4,1.7908001-4,4v8.0625H6.2119002   C2.7871001,14.03125,0,16.8183498,0,20.2431507V46.513649c0,3.4248009,2.7871001,6.2119026,6.2119002,6.2119026h2.3798995   c0.5527,0,1-0.4472008,1-1c0-0.5527-0.4473-1-1-1H6.2119002C3.8896,50.7255516,2,48.8359489,2,46.513649V20.2431507   c0-2.3223,1.8896-4.2119007,4.2119002-4.2119007h51.5762024C60.1102982,16.03125,62,17.9208508,62,20.2431507V46.513649   c0,2.3223-1.8897018,4.2119026-4.2118988,4.2119026H56c-0.5527992,0-1,0.4473-1,1c0,0.5527992,0.4472008,1,1,1h1.7881012   C61.2128983,52.7255516,64,49.9384499,64,46.513649V20.2431507C64,16.8183498,61.2128983,14.03125,57.7881012,14.03125z    M13.5,5.96875c0-1.1027999,0.8971996-2,2-2h33c1.1027985,0,2,0.8972001,2,2v8h-37V5.96875z" />
          <path d="M44,45.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,47.0302505,20,47.0302505h24   c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,45.0322495,44,45.0322495z" />
          <path d="M44,52.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,54.0302505,20,54.0302505h24   c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,52.0322495,44,52.0322495z" />
          <circle cx="7.9590998" cy="21.8405495" r="2" />
          <circle cx="14.2856998" cy="21.8405495" r="2" />
          <circle cx="20.6121998" cy="21.8405495" r="2" />
          <path d="M11,62.03125h42v-26H11V62.03125z M13.4036999,38.4349518h37.1925964v21.1925964H13.4036999V38.4349518z" />
        </g>
      </svg>
    ),
    download: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size ? size : 40}`}
        height={`${size ? size : 40}`}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          opacity="0.5"
          d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15"
          stroke="#1C274C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 3V16M12 16L16 11.625M12 16L8 11.625"
          stroke="#1C274C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    file: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size ? size : 40}`}
        height={`${size ? size : 40}`}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M9 17H15M9 13H15M9 9H10M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V9M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19"
          stroke="#000000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    close: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size ? size : 40}`}
        height={`${size ? size : 40}`}
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
      </svg>
    ),
    upload: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size ? size : 40}`}
        height={`${size ? size : 40}`}
        viewBox="0 0 640 512"
      >
        <path
          fill="lightgray"
          d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
          class=""
        ></path>
      </svg>
    ),
    profile: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size ? size : 40}`}
        height={`${size ? size : 40}`}
        viewBox="0 0 18 18"
      >
        <defs>
          <linearGradient
            id="a"
            x1="7.89"
            y1="6.9"
            x2="7.89"
            y2="19.35"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".22" stopColor="#32d4f5" />
            <stop offset="1" stopColor="#198ab3" />
          </linearGradient>
          <linearGradient
            id="b"
            x1="7.53"
            y1=".22"
            x2="8.44"
            y2="11.53"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".22" stopColor="#32d4f5" />
            <stop offset="1" stopColor="#198ab3" />
          </linearGradient>
          <radialGradient
            id="c"
            cx="-19.24"
            cy="6.51"
            r="6.13"
            gradientTransform="translate(32.03 6.26) scale(.94)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".27" stopColor="#ffd70f" />
            <stop offset="1" stopColor="#fea11b" />
          </radialGradient>
        </defs>
        <path
          d="M14.05 17.11a1.34 1.34 0 001.34-1.33.81.81 0 000-.16C14.86 11.42 12.47 8 7.9 8S.86 10.9.4 15.63a1.34 1.34 0 001.19 1.47h12.46z"
          fill="url(#a)"
        />
        <path
          d="M7.9 9a4.09 4.09 0 01-2.27-.67l2.25 5.89 2.24-5.85A4.17 4.17 0 017.9 9z"
          fill="#fff"
          opacity=".8"
        />
        <circle cx="7.9" cy="4.8" r="4.21" fill="url(#b)" />
        <g>
          <path
            d="M17.27 11.45a1.13 1.13 0 000-1.6l-1.94-2a1.12 1.12 0 00-1.6 0l-2 1.94a1.14 1.14 0 000 1.61l1.61 1.64a.31.31 0 01.09.22v3a.36.36 0 00.12.28l.73.75a.27.27 0 00.37 0l.72-.72.42-.43a.14.14 0 000-.2l-.31-.31a.17.17 0 010-.23l.31-.31a.13.13 0 000-.2l-.3-.31a.17.17 0 010-.23l.31-.31a.14.14 0 000-.2l-.42-.43v-.11zm-2.73-3.11a.66.66 0 01.64.65.63.63 0 01-.65.64.65.65 0 010-1.29z"
            fill="url(#c)"
          />
          <path
            d="M14 16.38a.14.14 0 00.24-.1v-2.45a.16.16 0 00-.06-.13.14.14 0 00-.22.12v2.46a.13.13 0 00.04.1z"
            fill="#ff9300"
            opacity=".75"
          />
          <rect
            x="14.38"
            y="9.07"
            width=".38"
            height="3.21"
            rx=".17"
            transform="rotate(-89.65 14.562 10.673)"
            fill="#ff9300"
            opacity=".75"
          />
          <rect
            x="14.37"
            y="9.68"
            width=".38"
            height="3.21"
            rx=".17"
            transform="rotate(-89.65 14.559 11.29)"
            fill="#ff9300"
            opacity=".75"
          />
        </g>
      </svg>
    ),
    success: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size ? size : 40}`}
        height={`${size ? size : 40}`}
        viewBox="0 0 18 18"
      >
        <defs>
          <linearGradient
            id="a"
            x1="-234.309"
            y1="776.485"
            x2="-234.309"
            y2="788.611"
            gradientTransform="scale(1 -1) rotate(-45 -1065.92 95.67)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#5e9624" />
            <stop offset=".82" stopColor="#76bc2d" />
          </linearGradient>
        </defs>
        <path
          d="M13.335 1.1a.571.571 0 01.807 0l.026.028a8.32 8.32 0 01-11.736 11.73.57.57 0 01-.049-.8l.025-.026A.571.571 0 013.19 12 7.178 7.178 0 0013.312 1.882a.573.573 0 01.023-.782"
          fill="#a3a3a3"
        />
        <circle cx="7.871" cy="6.563" r="6.063" fill="url(#a)" />
        <path
          d="M7.25 8.596l-.557.557a.243.243 0 01-.343 0L4.137 6.941a.243.243 0 010-.344l.385-.385a.243.243 0 01.344 0L7.25 8.596z"
          fill="#fff"
        />
        <path
          d="M6.361 9.157L5.804 8.6l5.091-5.092a.243.243 0 01.344 0l.385.386a.243.243 0 010 .343l-4.92 4.92a.243.243 0 01-.344 0z"
          fill="#f2f2f2"
        />
      </svg>
    ),
    error: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size ? size : 40}`}
        height={`${size ? size : 40}`}
        viewBox="0 0 18 18"
      >
        <defs>
          <linearGradient
            id="a"
            x1="-223.179"
            y1="749.615"
            x2="-223.179"
            y2="761.741"
            gradientTransform="scale(1 -1) rotate(-45 -1027.915 95.67)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#b31b1b" />
            <stop offset=".82" stopColor="#e62323" />
          </linearGradient>
        </defs>
        <path
          d="M13.335 1.1a.571.571 0 01.807 0l.026.028a8.32 8.32 0 01-11.736 11.73.57.57 0 01-.049-.8l.025-.026A.571.571 0 013.19 12 7.178 7.178 0 0013.312 1.882a.573.573 0 01.023-.782zM11.946"
          fill="#a3a3a3"
        />
        <circle cx="7.871" cy="6.563" r="6.063" fill="url(#a)" />
        <g fill="#f2f2f2">
          <path d="M8.391 8.105H7.256a.255.255 0 01-.265-.236l-.122-5.164a.254.254 0 01.266-.246h1.377a.254.254 0 01.266.246l-.122 5.164a.255.255 0 01-.265.236z" />
          <circle cx="7.823" cy="9.647" r=".905" />
        </g>
      </svg>
    ),
  };
  return svgItems[item] ? (
    svgItems[item]
  ) : (
    <span
      style={{
        width: `${size}px`,
        height: `${size}px`,
        verticalAlign: "middle",
        ...style,
      }}
    ></span>
  );
};
