import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import MenuIcon from "@material-ui/icons/Menu";
import {
  Avatar,
  Menu,
  MenuItem,
  Select,
  Grid,
  Button,
  Paper,
} from "@material-ui/core";
import { Redirect, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NavBar from "./Navbar";
import Footer from "./Footer";
const drawerWidth = 250;

function Wrapper({
  permissions,
  selectedFilter,
  children,
  history,
  theme,
  location,
  lastUpdated,
  auth,
  alerts,
}) {
  const useStyles = makeStyles((theme) => ({
    iconStyle: {
      width: "1em",
      height: "1em",
      // transform: "translateY(-25%)",
      position: "absolute",
    },

    menuItem: {
      padding: "6px 24px",
      fontWeight: 400,
      fontSize: "0.8125rem",
    },
    selectInputsStyle: {
      padding: "0px 5px 0px 5px",
      color: "#36A7D9 !important",
      fontSize: "0.9rem !important",
      // boxShadow:
      //   "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
      borderRadius: "6px",
      // backgroundColor: "#fff",
      fontWeight: 350,
      marginLeft: "auto",
      fontFamily: "Poppins",
      // maxWidth: "100px",
    },
    selectInputsStyleMobile: {
      padding: "0px 5px 0px 5px",
      color: "#363636 !important",
      width: "240px",
      boxShadow:
        "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
      fontSize: "0.9rem !important",
      borderRadius: "6px",
      // backgroundColor: "#fff",
      fontWeight: 350,
      fontFamily: "Poppins",
    },
    filterInputContainer: {
      // boxShadow:
      //   "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
      // padding: "7px 5px 5px 5px",
      // boxShadow: "none",
      // color: "#363636 !important",
      display: "flex",
      fontSize: "15px !important",
      borderRadius: "6px",
      fontWeight: 450,
      fontFamily: "Poppins",
      marginLeft: "auto",
    },
    filterInputContainerMobile: {
      display: "flex",
      fontSize: "15px !important",
      borderRadius: "6px",
      boxShadow:
        "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
      width: "240px",
      backgroundColor: "#fff",
      fontWeight: 450,
      fontFamily: "Poppins",
    },
    chipDataList: {
      listStyle: "none",
      display: "flex",
      marginBottom: 0,
      paddingLeft: 0,
      maxWidth: "240px",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        height: "3px",
      },
    },
    filterInputsStyle: {
      padding: "0px 2px",
      fontSize: "15px !important",
      borderRadius: "6px",
      fontWeight: 350,
      fontFamily: "Poppins",
    },
    sizeAvatar: {
      width: "30px",
      height: "30px",
      display: "inline-flex",
      cursor: "pointer",
    },
    styleText: {
      letterSpacing: "0.02em",
      fontSize: "15px",
      fontWeight: "500",
      paddingRight: "5px",
      margin: "auto",
      fontFamily: "Roboto",
    },
    pageTitelStyle: {
      fontSize: "17px",
      fontFamily: "'Poppins',Roboto",
      color: "#43425D",
      fontWeight: 450,
      textTransform: "capitalize",
      marginRight: "10px",
    },
    pageTitelStyleMobile: {
      fontSize: "15px",
      fontFamily: "'Poppins',Roboto",
      fontWeight: 450,
      textTransform: "capitalize",
      marginRight: "10px",
    },
    pageHeadingStyle: {
      fontSize: "17px",
      fontFamily: "'Poppins',Roboto",
      color: "#43425D",
      fontWeight: 450,
      textTransform: "capitalize",
    },
  }));

  const classes = useStyles();
  // let pathName = window.location.pathname;
  return (
    <>
      <div id="wrapper">
        <div className="page-wrapper">
          <div className="topbar">
            <NavBar />
          </div>
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">{children}</div>
              </div>
            </div>
          </div>
          <div className="footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  permissions: state.permissions,
  selectedFilter: state.auth.selectedFilter,
  lastUpdated: state.lastUpdated,
  alerts: state.addRemoveAlert.alerts,
  theme: state.theme,
});
// export default connect(mapStateToProps, {})(withRouter(SideBar));
export default Wrapper;
