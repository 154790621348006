import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import { userLogout } from "../Redux/Actions";
import Container from "@mui/material/Container";
import { styled } from "@mui/system";
// import Logo from "../../public/dummy-logo.png";
import { SvgContainer } from "../assets/svgContainer";
import { useHistory } from "react-router-dom";
import { publicNavs, privateNavs } from "./navigation";
import { connect } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { makeStyles, Menu, MenuItem } from "@material-ui/core";
const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none", // Preserve original text case
  "&:hover": {
    color: "blue", // Change color on hover
    // fontSize: "1rem",
    // fontWeight: "600",
  },
}));

const useStyles = makeStyles({
  sizeAvatar: {
    width: "30px !important",
    height: "30px !important",
    display: "inline-flex !important",
    cursor: "pointer !important",
  },
  caret: {
    display: "inline-block",
    width: "0px",
    height: "0px",
    cursor: "pointer",
    marginLeft: "3px",
    verticalAlign: "middle",
    borderTop: "4px dashed",
    borderRight: "4px solid transparent",
    borderLeft: "4px solid transparent",
  },
  menuItem: {
    padding: "6px 10px",
    fontWeight: 400,
    fontSize: "0.8125rem",
    alignItems: "center",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  menuItemFirst: {
    padding: "6px 10px",
    fontWeight: 400,
    fontSize: "0.8125rem",
    alignItems: "center",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    backgroundColor: "#fff",
    cursor: "default",
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus-visible": {
      backgroundColor: "#fff",
    },
  },
  menuIcon: {
    marginRight: "5px",
  },
  styleText: {
    letterSpacing: "0.02em",
    fontSize: "15px",
    fontWeight: "500",
    margin: "auto",
    // color: "#2c3652",
  },
});

const NavBar = ({ auth, userLogout }) => {
  const history = useHistory();
  const classes = useStyles();
  const userName = JSON.parse(localStorage.getItem("user"))?.first_name || "";
  const isCarrier = auth?.user?.role === "carrier";
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const [avatarAnchor, setAvatarAnchor] = React.useState(null);

  const [permittedNavs, setPermittedNavs] = useState([]);
  const [authValue, setAuthValue] = useState(false);
  useEffect(async () => {
    if (Object.keys(auth.user).length > 0 || localStorage.getItem("user")) {
      if (isCarrier) {
        const newNavs = privateNavs.filter((ite, index) => {
          if (!["/post"].includes(ite.url)) {
            return ite;
          }
        });
        setPermittedNavs(newNavs);
      } else {
        setPermittedNavs(privateNavs);
      }
    } else {
      setPermittedNavs(publicNavs);
    }
  }, [auth]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleMenuClose = (path) => {
    history.push(path);
    setAnchorEl(null);
  };

  const handleLogin = (path) => {
    history.push(path);
  };
  const handleAvatarClick = (event) => {
    setAvatarAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAvatarAnchor(null);
  };
  const handleProfile = () => {
    setAvatarAnchor(null);
    history.push("/view-profile");
  };
  const handleLogout = () => {
    setAvatarAnchor(null);
    userLogout(history);
  };
  const handleClick = (e, item) => {
    if (item.url === "/signout") {
      userLogout(history);
    } else if (item.url === "/signup") {
      handleMenuOpen(e);
    } else {
      setAnchorElMenu(null);
      history.push(item.url);
    }
  };
  const handleLogo = () => {
    history.push("/welcome");
  };
  return (
    <>
      <Container maxWidth="xl">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img
              src={`${window.location.origin}/dummy-logo.png`}
              onClick={handleLogo}
              width={50}
              style={{ cursor: "pointer" }}
              height={50}
            />
          </Typography>
          {/* Display Menu Icon for small screens */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
            sx={{ display: { xs: "block", sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {/* Display Buttons for larger screens */}
          {permittedNavs.map((item) => {
            return (
              <StyledButton
                color="inherit"
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                    // "&:hover": {
                    //   fontW
                    // },
                  },
                }}
                onClick={(e) => handleClick(e, item)}
              >
                {item.name}
              </StyledButton>
            );
          })}
          {userName && (
            <div className="nav-link">
              <Grid
                onClick={handleAvatarClick}
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                  },
                }}
              >
                <Avatar className={classes.sizeAvatar}>
                  {userName.charAt(0).toUpperCase()}{" "}
                </Avatar>
                <span className={classes.caret} />
              </Grid>
              <Menu
                anchorEl={avatarAnchor}
                anchorOrigin={{
                  horizontal: "left",
                  vertical: "bottom",
                }}
                keepMounted
                open={Boolean(avatarAnchor)}
                onClose={handleClose}
              >
                <MenuItem disableRipple className={classes.menuItemFirst}>
                  <span className={classes.menuIcon}>
                    <SvgContainer item={"user"} size={15} />
                  </span>
                  <span>
                    <p className={classes.styleText}> {userName}</p>
                  </span>
                </MenuItem>
                <MenuItem
                  disableRipple
                  className={classes.menuItem}
                  onClick={handleProfile}
                >
                  <span className={classes.menuIcon}>
                    <SvgContainer item={"profile"} size={15} />
                  </span>
                  <span>
                    <p className={classes.styleText}> Profile</p>
                  </span>
                </MenuItem>
                <MenuItem
                  // key={item.name}
                  className={classes.menuItem}
                  onClick={handleLogout}
                >
                  <span className={classes.menuIcon}>
                    <SvgContainer item={"signout"} size={15} />
                  </span>

                  <span>
                    <p className={classes.styleText}> Sign Out</p>
                  </span>
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </Container>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          sx={{
            "&:hover": {
              color: "blue",
            },
          }}
          onClick={() => handleMenuClose("/signup/broker")}
        >
          Broker
        </MenuItem>
        <MenuItem
          sx={{
            "&:hover": {
              color: "blue",
            },
          }}
          onClick={() => handleMenuClose("/signup/shipper")}
        >
          Shipper
        </MenuItem>
        <MenuItem
          sx={{
            "&:hover": {
              color: "blue",
            },
          }}
          onClick={() => handleMenuClose("/signup/carrier")}
        >
          Carrier
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorElMenu}
        open={Boolean(anchorElMenu)}
        onClose={() => setAnchorElMenu(null)}
      >
        {permittedNavs.map((item, index) => {
          if (Array.isArray(item.child) && item.child.length > 0) {
            return item.child.map((childItem, childIndex) => {
              return (
                <MenuItem
                  key={`${index}+${childIndex}`}
                  onClick={(e) => handleClick(e, childItem)}
                  className={classes.menuItem}
                >
                  {childItem.name}
                </MenuItem>
              );
            });
          } else {
            return (
              <MenuItem
                key={index}
                className={classes.menuItem}
                onClick={(e) => handleClick(e, item)}
              >
                {item.name}
              </MenuItem>
            );
          }
        })}
        {userName && (
          <MenuItem
            // key={}
            className={classes.menuItem}
            onClick={(e) => handleLogout(e)}
          >
            Sign Out
          </MenuItem>
        )}
        {/* <MenuItem onClick={() => handleMenuClose("/services")}>
          Services
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose("/references")}>
          References
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose("/signup/broker")}>
          Broker
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose("/signup/shipper")}>
          Shipper
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose("/signup/carrier")}>
          Carrier
        </MenuItem> */}
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth });
export default connect(mapStateToProps, { userLogout })(NavBar);
