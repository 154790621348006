import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { connect } from "react-redux";
import {
  Button,
  CircularProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { SvgContainer } from "../../assets/svgContainer";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../api";
const useStyles = makeStyles({
  loginBtn: {
    color: "#fff",
    padding: "1rem 14px",
    borderRadius: "6px",
    textTransform: "none",
    fontSize: "large",
    backgroundColor: "#428BCA",
    width: "100%",
    margin: "5rem 0",
    "&:hover": {
      backgroundColor: "#428BCA",
    },
  },
});
function VerifyEmail() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [verified, setVerified] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [parentMessage, setParentMessage] = useState("");
  useEffect(() => {
    if (id) {
      api({
        url: `/verify_email/${id}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
      })
        .then((res) => {
          if (res?.data?.success && res?.data?.message) {
            setVerified(true);
            setMessage(res?.data?.message || "");
            setParentMessage("YOUR ACCOUNT HAS BEEN VERIFIED.");
          } else {
            setVerified(true);
            setMessage(res?.data?.message || "");
            setParentMessage("SOME THING WENT WRONG.");
            setError(true);
          }
        })
        .catch((err) => {
          if (err) {
            setVerified(false);
            setError(true);
            setMessage("Some thing went wrong");
            setParentMessage("SOME THING WENT WRONG.");
          }
        });
    }
  }, []);
  let style = { padding: "20px", textAlign: "center" };
  const handleLogin = () => {
    history.push("/login");
  };
  return (
    <div>
      <Container
        maxWidth="md"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          minHeight: "400px",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid #ccc", // Add border styling
          borderRadius: "8px", // Optional: Add rounded corners
          padding: "1rem", // Optional: Add padding
          backgroundColor: "#fff",
          textAlign: "center",
        }}
      >
        {verified ? (
          <div>
            <Typography
              variant="h4"
              gutterBottom
              style={{ fontFamily: "Roboto" }}
            >
              {parentMessage}
            </Typography>
            {error ? (
              <SvgContainer item={"error"} size={150} />
            ) : (
              <SvgContainer item={"success"} size={150} />
            )}
            <p style={{ fontFamily: "Roboto" }}>
              {/* We have successfully verified your account, You can now continue
              to your account. */}
              {message}
            </p>
            {error ? (
              ""
            ) : (
              <Button className={classes.loginBtn} onClick={handleLogin}>
                Login
              </Button>
            )}
          </div>
        ) : (
          <>
            <div>
              <Typography
                variant="h4"
                gutterBottom
                style={{ fontFamily: "Roboto" }}
              >
                YOUR ACCOUNT IS BEING VERIFIED.
              </Typography>
              {/* <SvgContainer item={"verify"} size={150} /> */}
              {/* <p style={{ fontFamily: "Roboto" }}>
              We have successfully verified your account, You can now continue
              to your account.
            </p> */}
            </div>
            <div style={style}>
              <CircularProgress
                style={{
                  width: "75px",
                  height: "75px",
                  color: "#08f",
                  "&:hover": {
                    color: "#fff !important ",
                  },
                }}
              />
            </div>
          </>
        )}
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(VerifyEmail);
