import { Divider, Grid, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import Input from "./Input.js";
import Button from "@mui/material/Button";
import * as yup from "yup";
import React, { useState, useEffect, Fragment } from "react";
import { useFormik } from "formik";
import { createYupSchema } from "./validationSchema";
// import { renderButtons } from "../ModalButtons";
import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useHistory } from "react-router-dom";
function FormGenerator({
  fields,
  buttonText,
  step,
  style,
  onSubmit,
  errors,
  addComp,
  initialVal,
  handleNextClick,
  hideButtons = false,
  getValues,
  spacing,
  displayCancel = true,
  modalClose,
  compProps = {},
  goToHomeBtn = false,
}) {
  let otherEl = ["html", "space", "custom"];
  const history = useHistory();
  const getField = () => {
    let obj = {};
    fields.forEach((item) => {
      if (!otherEl.includes(item.field)) {
        if (item.field === "checkbox") {
          obj[item.name] = false;
        } else {
          obj[item.name] = "";
        }
      }
    });
    return { ...obj, ...initialVal };
  };
  const [formFields, setFormFields] = useState({ fields });
  const [disable, setDisable] = useState(false);
  useEffect(() => {
    setFormFields(fields);
    if (typeof getValues !== "undefined") {
      getValues(formik);
    }
  }, [fields]);
  const yepSchema = fields.reduce(createYupSchema, {});
  const formik = useFormik({
    initialValues: getField(),
    initialErrors: errors && Object.keys(errors).length > 0 ? errors : {},
    initialTouched: errors && Object.keys(errors).length > 0 ? errors : {},
    validationSchema: yup.object().shape(yepSchema),
    onSubmit: (values) => {
      if (onSubmit) {
        onSubmit(values, formik, step, setDisable);
      }
    },
    enableReinitialize: true,
  });
  const useStyles = makeStyles((theme) => ({
    root: {
      // padding: "2%",
      color: "var(--txt-clr)",
      // font: 'normal normal normal 18px/13px Source Sans Pro'
    },
    formControl: {
      margin: theme.spacing(1),
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    select: {
      // marginTop: "16px",
    },
    button: {
      paddingTop: "10px",
      // marginTop: "30px",
    },
    gridButton: {
      justifyContent: goToHomeBtn ? "space-between" : "end",
      alignItems: "center",
    },
    btnColor: {
      background: "#0f4a7e",
      "&:hover": {
        background: "#0f4a7e",
      },
      border: "1px solid #0F4A7E",
    },
    btn: {
      margin: theme.spacing(1),
      position: "relative",
    },
    buttonProgress: {
      backgroundColor: "#1761fd",
      color: "#fff",
      padding: "0.25rem 0.5rem",
      fontSize: ".8125rem",
      marginRight: "6px",
      border: "none",
      borderRadius: "0.2rem",
      "&:hover": {
        backgroundColor: "#1761fd",
        color: "#fff",
      },
    },
    cancelButton: {
      backgroundColor: "rgba(245,50,92,0.2)",
      color: "#f5325c",
      padding: "0.25rem 0.5rem",
      fontSize: ".8125rem",
      borderRadius: "0.2rem",
      border: "none",
      "&:hover": {
        backgroundColor: "#f5325c",
        color: "#fff",
      },
    },

    ...style,
  }));
  const handleTabButtons = (text) => {
    handleNextClick(text);
  };
  const handleButtons = () => {
    return (
      <Grid container spacing={2} justifyContent="flex-end">
        {step && step !== 0 ? (
          <Grid item>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#29323D",
                color: "white",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#1C2229",
                },
              }}
              disabled={disable}
              type="button"
              onClick={() => handleTabButtons("previous")}
            >
              {"Previous Step"}
            </Button>
          </Grid>
        ) : (
          <Grid item>
            {displayCancel && (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#29323D",
                  color: "white",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#1C2229",
                  },
                }}
                disabled={disable}
                type="button"
                onClick={modalClose ? modalClose : () => {}}
              >
                {"Cancel"}
              </Button>
            )}
          </Grid>
        )}
        <Grid item>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "rgba(25, 135, 84, 1) !important",
              color: "white",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "rgba(25, 135, 84, 1) !important",
              },
            }}
            disabled={disable}
            type="submit"
            // onClick={() => handleTabButtons("next")}
          >
            {disable ? (
              <CircularProgress
                style={{
                  width: "25px",
                  height: "25px",
                  color: "#fff",
                  "&:hover": {
                    color: "#fff !important ",
                  },
                }}
              />
            ) : buttonText ? (
              buttonText
            ) : (
              "Previous Step"
            )}
          </Button>
        </Grid>
      </Grid>
    );
  };
  const classes = useStyles();
  return (
    <Fragment>
      <form
        id="formik-form"
        onSubmit={formik.handleSubmit}
        className={classes.root}
      >
        {" "}
        <Grid container spacing={spacing ? spacing : 3}>
          {fields.map((item, index) => {
            return (
              <Grid
                key={item.name}
                item
                md={item.col}
                sm={item.col}
                xs={item.name == "CompanyName" || "CompanyURL" ? 12 : item.col}
                lg={item.col}
                xl={item.col}
                className={
                  item.className
                    ? classes[item.className]
                    : item.field === "select"
                    ? classes.select
                    : ""
                }
                style={item.containerStyle ? item.containerStyle : {}}
              >
                <Input
                  item={item}
                  formik={formik}
                  setDisable={setDisable}
                ></Input>
              </Grid>
            );
          })}
        </Grid>
        {addComp && addComp({ ...compProps, formik, setDisable })}
        <br />
        <br />
        {!hideButtons && handleButtons()}
      </form>
      {/* {disable && <LinearProgress />} */}
    </Fragment>
  );
}

export default FormGenerator;

FormGenerator.propTypes = {
  style: PropTypes.object,
  fields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func,
  addComp: PropTypes.func,
  initialVal: PropTypes.object,
  repeater: PropTypes.bool,
  modalButtonText: PropTypes.string,
};
