import React, { Suspense, Fragment, useState, useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { connect } from "react-redux";
import { useStyles } from "./themes/makeStyles.js";
import { theme } from "./themes/index.js";
import {
  Route,
  // BrowserRouter as Router,
  HashRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import { authRoutes } from "./routes.js";
import "./App.css";
import Layout from "./Views/Layout.js";
import Wrapper from "./Views/Wrapper.js";
import { createTheme, MuiThemeProvider, makeStyles } from "@material-ui/core";

function App({ auth, history }) {
  const classes = useStyles();

  return (
    <Fragment>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        autoHideDuration={6000}
      >
        <MuiThemeProvider theme={theme}>
          <Suspense fallback={<div className="loader" />}>
            <Router history={history}>
              <Switch>
                <Route
                  render={(props) => {
                    return React.createElement(Layout);
                  }}
                />
              </Switch>
            </Router>
          </Suspense>
        </MuiThemeProvider>
      </SnackbarProvider>
    </Fragment>
  );
}
const mapStateToProps = (state) => ({ auth: state.auth });
export default connect(mapStateToProps, {})(App);
