import { store } from "../store";
import { api } from "../../api";
import { LOGIN, LOGOUT } from "../types";

export const confirmInsurance =
  (
    payload,
    formik,
    enqueueSnackbar,
    setDisable,
    setDetailsModalLoader,
    setConfirmationInsuranceModal
  ) =>
  async (dispatch) => {
    setDetailsModalLoader(true);
    await api({
      url: "/confirm_insurance_certificate_document_by_shipper",
      method: "post",
      data: { ...payload },
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    })
      .then((res) => {
        if (res) setDisable(false);

        setConfirmationInsuranceModal(false);
        if (res?.data) {
          enqueueSnackbar(res?.data?.message || "some thing went wrong", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        setDisable(false);
        if (enqueueSnackbar) {
          enqueueSnackbar(
            err?.response?.data?.error || "some thing went wrong",
            { variant: "error" }
          );
        }
      });
  };
export const requestInsurance =
  (
    payload,
    formik,
    enqueueSnackbar,
    setDisable,
    setDetailsModalLoader,
    setRequestInsuranceModal
  ) =>
  async (dispatch) => {
    await api({
      url: "/send_insurance_certificate_request",
      method: "post",
      data: { ...payload },
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    })
      .then((res) => {
        if (res) {
          setDisable(false);
          setDetailsModalLoader(true);
          setRequestInsuranceModal(false);
          if (enqueueSnackbar) {
            enqueueSnackbar(res?.data?.message || "some thing went wrong", {
              variant: "success",
            });
          }
        }
      })
      .catch((err) => {
        setDisable(false);
        if (enqueueSnackbar) {
          enqueueSnackbar(
            err?.response?.data?.error || "some thing went wrong",
            { variant: "error" }
          );
        }
      });
  };
export const customFileUpload =
  (
    formData,
    formik,
    enqueueSnackbar,
    setDisable,
    setFileUploadModal,
    setDetailsModalLoader
  ) =>
  async (dispatch) => {
    api({
      method: "post",
      url: "/store_load_custom_detail_document",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    })
      .then((res) => {
        if (res.data.success && res.data.message) {
          setDisable(false);
          setFileUploadModal(false);
          setDetailsModalLoader(false);
          enqueueSnackbar(res?.data?.message, { variant: "success" });
        }
      })
      .catch((err) => {
        setDisable(false);
        console.log(err);
        if (err.response.data.errors) {
          let error = err.response.data.errors;
          Object.keys(error).forEach((item) => {
            enqueueSnackbar(error[item][0] || "Something went wrong!", {
              variant: "error",
            });
          });
          formik.setErrors(err.response.data.errors);
        }
      });
  };
export const downloadCustomFile =
  (apiData, reSetModal, stop_id, additional_stop_id) => async (dispatch) => {
    let url = "";
    if (stop_id) {
      url = additional_stop_id
        ? `/download_custom_load_document/${apiData?.load_id}/${stop_id}/${additional_stop_id}`
        : `/download_custom_load_document/${apiData?.load_id}/${stop_id}`;
    } else {
      url = `/download_custom_load_document/${apiData?.load_id}`;
    }
    api({
      method: "post",
      url: url,
      data: apiData,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    })
      .then((res) => {
        if (res.data) {
          if (reSetModal) {
            reSetModal(false);
          }
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${apiData.document_type}.pdf`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        console.log(err);
        if (reSetModal) {
          reSetModal(false);
        }
        if (err?.response?.data?.errors) {
        }
      });
  };
export const downloadInsuranceCertificate =
  (apiData, formik, enqueueSnackbar) => async (dispatch) => {
    api({
      method: "get",
      url: `/download_insurance_certificate_document/${apiData?.load_id}`,
      data: apiData,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    })
      .then((res) => {
        if (res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${apiData.document_type}.pdf`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.data.message) {
          enqueueSnackbar(err?.data?.message || "Something went wrong!", {
            variant: "error",
          });
        }
      });
  };
export const uploadInsuranceDocument =
  (formData, formik, enqueueSnackbar, setDisable, setIsSubmitted) =>
  async (dispatch) => {
    api({
      method: "post",
      url: "/submit_insurance_document_by_broker_agent",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    })
      .then((res) => {
        if (res.data.success && res.data.message) {
          setDisable(false);
          setIsSubmitted(true);
          enqueueSnackbar(res?.data?.message, { variant: "success" });
        }
      })
      .catch((err) => {
        setDisable(false);
        if (err.response.data.message) {
          enqueueSnackbar(
            err.response.data.message || "Something went wrong!",
            {
              variant: "error",
            }
          );
        }
      });
  };
export const completeLoad =
  (
    apiData,
    enqueueSnackbar,
    setDetailsModalLoader,
    setDisable,
    setCompleteLoadModal
  ) =>
  async (dispatch) => {
    api({
      method: "post",
      url: "/complete_load",
      data: apiData,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    })
      .then((res) => {
        if (res.data.success && res.data.message) {
          setCompleteLoadModal(false);
          setDetailsModalLoader(true);
          setDisable(false);
          enqueueSnackbar(res?.data?.message, { variant: "success" });
        }
      })
      .catch((err) => {
        if (err.response.data.message) {
          setDisable(false);
          enqueueSnackbar(
            err.response.data.message || "Something went wrong!",
            {
              variant: "error",
            }
          );
        }
      });
  };
