import * as React from "react";
import {
  Checkbox,
  Select,
  MenuItem,
  OutlinedInput,
  FormHelperText,
  Grid,
  InputAdornment,
  CircularProgress,
  FormControlLabel,
  IconButton,
  makeStyles,
  Typography,
  Box,
  InputLabel,
} from "@material-ui/core";
import { Dayjs } from "dayjs";
import TextField from "@material-ui/core/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";

const useStyles = makeStyles((theme) => ({
  customstyle: { ...theme.customInputStyle },
  select: {
    marginTop: "0px",
  },
  textFieldLabel: {
    color: "var(--txt-f-l-clr)",
    marginBottom: theme.spacing(1),
    fontSize: "13px",
    textAlign: "left",
    fontWeight: 600,
    fontFamily: "Roboto, sans-serif",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  textFieldError: {
    color: "#F44336",
  },
}));
export default function BasicDatePicker({ item, formik }) {
  const classes = useStyles();
  let { values, handleChange, errors, touched } = formik;

  const [value, setValue] = React.useState(null);

  const currentDate = new Date();
  // Format the current date as "YYYY-MM-DD" (adjust the format as needed)
  const formattedCurrentDate = currentDate.toISOString().split("T")[0];
  React.useEffect(() => {
    formik.setFieldValue(item.name, formattedCurrentDate);
  }, [item]);

  const handleSelectChange = (e) => {
    //   handleChange(e);
    e.preventDefault();
    formik.setFieldValue(item.name, e.target.value);
    setValue(e.target.value);
    // item?.callBackVal && item?.callBackVal(e.target.value);
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel className={classes.textFieldLabel}>{item.label}</InputLabel>
        <TextField
          id="date"
          type="date"
          defaultValue={formattedCurrentDate}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleSelectChange}
          InputProps={{
            disableUnderline: true,
            style: {
              width: "100%",
              padding: "0.5rem",
              fontSize: " 0.95rem",
              fontWeight: 500,
              lineHeight: 1.5,
              color: "var(--txt-f-clr)",
              backgroundColor: item.disabled ? "var(--txt-f-disabled)" : "",
              backgroundClip: "padding-box",
              border: "1px solid #C9D3DD",
              appearance: "none",
              borderRadius: "6px",
              transition:
                "border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out",
            },
            readOnly: item.readOnly ? item.readOnly : false,
          }}
        />
      </FormControl>
      {errors[item.name] && touched[item.name] && (
        <FormHelperText className={classes.textFieldError}>
          {errors[item.name]}
        </FormHelperText>
      )}{" "}
    </>
  );
}
