import axios from "axios";
// import { store } from "./store";

let axiosOption = {
  headers: {
    "content-type": ["application/json"],
    accept: ["application/json", "application/xlsx", "text/csv"],
    // 'HTTP_X_FORWARDED_PROTO': 'https'
  },
  baseURL: process.env.REACT_APP_SERVER_URL,
};

const api = async (config) => {
  let conf = {
    ...config,
    ...axiosOption,
  };
  if (config.headers) {
    conf = { ...conf, headers: { ...axiosOption.headers, ...config.headers } };
  }
  let res = await axios(conf);

  return res;
};
export { api, axiosOption };
