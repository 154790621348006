import { LOGIN, LOGOUT } from "../types";

const defaultState = {
  user: {},
};
export const AuthReducer = (state = defaultState, payload) => {
  switch (payload.type) {
    case LOGIN: {
      return { ...state, user: payload.payload };
    }
    case LOGOUT: {
      return defaultState;
    }
    default:
      return state;
  }
};
