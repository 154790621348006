import { Drawer } from "@material-ui/core";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { routes, memberRoutes, authRoutes } from "../routes.js";
import Wrapper from "./Wrapper.js";
import { setUserVal } from "../Redux/Actions/index.js";
import { useHistory } from "react-router-dom";
// import { NavigationList, MemberList } from "../../nav";
// import Error from "./Error";
import { NotFoundPage } from "./NotFound.js";
import Verify from "./verification/VerifyCarrier.js";
import VerifyEmail from "./verification/VerifyEmail.js";
import ThankYou from "./ThankYou.js";
const theme = createTheme({
  tableHeading: {
    font: "normal normal normal 28px/20px Source Sans Pro,Regular",
    fontFamily: "sans-serif",
  },
  formHeading: {
    font: "normal normal normal 21px/8px  Source Sans Pro",
    letterSpacing: "0px",
    color: "#3E3E3E",
    opacity: "0.5",
    marginBottom: "16px",
    textAlign: "center",
  },
  logo: {
    width: "24%",
    marginTop: "2%",
    ["@media (max-width:780px)"]: {
      width: "65%",
      cursor: "pointer",
      marginTop: "4%",
      marginLeft: "0%",
    },
  },
});
function Layout({ auth, setUserVal }) {
  const history = useHistory();
  const isCarrier = auth?.user?.role === "carrier";
  const [permittedRoutes, setPermittedRoutes] = useState([]);
  const [authValue, setAuthValue] = useState(false);
  useEffect(() => {
    let newArr = [];
    if (localStorage.getItem("user") && Object.keys(auth.user).length <= 0) {
      setUserVal();
    }
    if (localStorage.getItem("user")) {
      if (isCarrier) {
        const newRoutes = routes.filter((item, index) => {
          if (!["/post"].includes(item.path)) {
            return item;
          }
        });
        setPermittedRoutes(newRoutes);
      } else {
        setPermittedRoutes(routes);
      }
    } else {
      setPermittedRoutes(authRoutes);
    }
  }, [auth]);

  let isPresent = false;
  let pathName = window.location.hash.replace("#", "");
  return (
    <>
      <div>
        <Wrapper>
          {permittedRoutes.length > 0 ? (
            <Switch>
              {permittedRoutes.map((item) => {
                if (item.path === pathName) {
                  isPresent = true;
                }
                return (
                  <Route
                    key={item.path}
                    path={item.path}
                    exact
                    component={item.component}
                  />
                );
              })}
              <Route
                key={"/verify_carrier/:string"}
                path={"/verify_carrier/:string/:loadId"}
                exact
                component={Verify}
              />
              <Route
                key={"/verify_email"}
                path={"/verify_email/:id"}
                exact
                component={VerifyEmail}
              />
              {isPresent ? (
                <Redirect
                  to={{
                    pathname: pathName,
                  }}
                />
              ) : (
                <Redirect
                  to={{
                    pathname: permittedRoutes[0]?.path,
                  }}
                />
              )}
              <Route path="*" component={NotFoundPage} />
            </Switch>
          ) : (
            <div className="loader"></div>
          )}
        </Wrapper>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { setUserVal })(withRouter(Layout));
