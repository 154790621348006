import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  FormControlLabel,
  makeStyles,
  Typography,
  InputLabel,
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

const useStyles = makeStyles((theme) => ({
  textFieldError: {
    color: "#F44336",
  },
  textFieldLabel: {
    color: "var(--txt-f-l-clr)",
    marginBottom: theme.spacing(1),
    fontSize: "13px",
    textAlign: "left",
    fontWeight: 600,
    fontFamily: "Roboto, sans-serif",
  },
}));
export default function FileUploader({ item, formik }) {
  const classes = useStyles();
  let { values, handleChange, errors, touched } = formik;
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    // handleChange(event);
    formik.setFieldValue(item.name, event.target.files[0]);
  };
  const [files, setFiles] = useState([]);
  const [totalSize, setTotalSize] = useState(0);

  // useEffect(() => {
  //   if (files.length > 0) {
  //     formik.setFieldValue(item.name, selectedFiles);
  //   }
  // }, [selectedFiles]);
  const handleClick = () => {
    document.getElementById(item?.name).click();
  };
  return (
    <>
      <InputLabel className={classes.textFieldLabel}>{item.label}</InputLabel>
      <div>
        <TextField
          onClick={handleClick}
          fullWidth
          variant="standard"
          value={
            selectedFiles.length > 0
              ? selectedFiles.map((file) => file.name).join(", ")
              : ""
          }
          InputProps={{
            endAdornment: (
              <Button
                disableRipple
                style={{
                  backgroundColor: "#edeef2",
                  width: "200px",
                  color: "#000000",
                  fontSize: "13px",
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 600,
                  textTransform: "none",
                  padding: "0.78rem",
                  borderRadius: "0px 6px 8px 0px",
                  fontSize: " 0.95rem",
                  fontWeight: 500,
                  lineHeight: 1.5,
                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
              >
                Browse Files
                <input
                  type="file"
                  multiple
                  name={item.name}
                  id={item.name}
                  accept={item.accept ? item.accept : "image/*,.pdf,.doc,.docx"}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </Button>
            ),
            readOnly: true,
            disableUnderline: true,
            style: {
              width: "100%",
              color: "var(--txt-f-clr)",
              backgroundColor: item.disabled ? "var(--txt-f-disabled)" : "",
              backgroundClip: "padding-box",
              padding: "0 0 0 0.5rem",
              fontSize: "0.95rem",
              border: "1px solid #C9D3DD",
              appearance: "none",
              borderRadius: "6px",
              transition:
                "border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out",
            },
          }}
          style={{
            backgroundColor: item.readOnly ? "var(--txt-f-disabled)" : "",
          }}
        ></TextField>
      </div>
      {errors[item.name] && touched[item.name] && (
        <FormHelperText className={classes.textFieldError}>
          {errors && errors[item.name]}
        </FormHelperText>
      )}
    </>
  );
}
