import React from "react";

const Login = React.lazy(() => import("./Views/Login"));
const LoadBoard = React.lazy(() => import("./Views/Loads/LoadBoard"));
const SignupShipper = React.lazy(() => import("./Views/SignUp/SignupShipper"));
const Post = React.lazy(() => import("./Views/Loads/PostLoad"));
const Loads = React.lazy(() => import("./Views/Loads/MyLoads.js"));
const Verify = React.lazy(() =>
  import("./Views/verification/VerifyCarrier.js")
);
const SignupCarrier = React.lazy(() =>
  import("./Views/SignUp/SignupCarrier.js")
);
const SignupBroker = React.lazy(() => import("./Views/SignUp/SignupBroker.js"));
const UpdateProfile = React.lazy(() => import("./Views/Profile/UpdateProfile"));
const ViewProfile = React.lazy(() => import("./Views/Profile/ViewProfile.js"));

// const DashboardV2 = React.lazy(() =>
//   import("./src/views/dashboarTemplate/HomePage")
// );

const authRoutes = [
  {
    path: "/",
    // permissions: [VIEW_DASHBOARD],
    component: Login,
    exact: true,
  },
  {
    path: "/signup/shipper",
    component: SignupShipper,
    exact: true,
  },
  {
    path: "/signup/carrier",
    component: SignupCarrier,
    exact: true,
  },
  {
    path: "/signup/broker",
    component: SignupBroker,
    exact: true,
  },
  {
    path: "/login",
    component: Login,
    exact: true,
  },
];

const routes = [
  // {
  //   path: "/",
  //   // permissions: [VIEW_DASHBOARD],
  //   component: Welcome,
  //   exact: true,
  // },
  {
    path: "/welcome",
    // permissions: [VIEW_DASHBOARD],
    component: LoadBoard,
    exact: true,
  },
  {
    path: "/post",
    component: Post,
    exact: true,
  },
  {
    path: "/loads",
    component: Loads,
    exact: true,
  },
  // {
  //   path: "/profile",
  //   component: UpdateProfile,
  //   exact: true,
  // },
  {
    path: "/view-profile",
    component: ViewProfile,
    exact: true,
  },
  // {
  //   path: "/verify_carrier/:string",
  //   component: Verify,
  //   exact: true,
  // },
];

export { routes, authRoutes };
