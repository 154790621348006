import * as yup from "yup";
// import "yup-phone";
import { parsePhoneNumber, isValidNumber } from "libphonenumber-js";
export function createYupSchema(schema, config) {
  const ErrorMessage = {
    required: "It is a  field",
    email: "Please enter a valid email address",
    url: "Please enter a valid URL",
  };
  const { name, validationType, validations = [] } = config;
  if (!yup[validationType]) {
    return schema;
  }
  let validator = yup[validationType]();
  validations?.forEach((validation) => {
    const { params, type, message, matchesMsg, custom = false } = validation;
    if (!validator[type] && type != "phone") {
      return;
    }
    if (type === "matches") {
      validator = validator[type](
        params,
        `Invalid ${config.label}. ${matchesMsg ? matchesMsg : ""} `
      );
    } else if (type === "max") {
      validator = validator[type](params, message);
    } else if (type === "min") {
      validator = validator[type](params, message);
    } else if (type === "phone") {
      validator = validator.test({
        name: "phone",
        message: "Invalid phone number",
        test: (value) => {
          return isValidNumber(`${value}`, "US");
        },
      });
    } else if (type === "required") {
      validator = validator[type](`${config.label} is required. `);
    } else if (type === "integer") {
      validator = validator[type](
        custom ? message : `${config.label} value should be whole number. `
      );
    } else {
      validator = validator[type](custom ? params : ErrorMessage[type]);
    }
  });
  schema[name] = validator;

  return schema;
}
