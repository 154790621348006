import React from "react";
import {
  SelectInput,
  TextInput,
  CheckBox,
  TextArea,
  TextBox,
  PhoneNumber,
  StyleSelectInput,
} from "./fieldName.js";
import FileUploader from "./FileUploader.js";
import BasicDatePicker from "./DatePicker.js";
import TimePicker from "./TimePicker.js";
import LocationPicker from "./LocationPicker.js";
export default function Input({ item, formik, setDisable }) {
  let fields = {
    select: <SelectInput item={item} formik={formik} />,
    text: <TextInput item={item} formik={formik} />,
    checkbox: <CheckBox item={item} formik={formik} />,
    textarea: <TextArea item={item} formik={formik} />,
    displayText: <TextBox item={item} formik={formik} />,
    phone: <PhoneNumber item={item} formik={formik} />,
    fileUpload: <FileUploader item={item} formik={formik} />,
    styleSelect: <StyleSelectInput item={item} formik={formik} />,
    datePicker: <BasicDatePicker item={item} formik={formik} />,
    timePicker: <TimePicker item={item} formik={formik} />,
    locationPicker: <LocationPicker item={item} formik={formik} />,
    space: <div></div>,

    html: item.render,
    custom: <h1> New Component</h1>,
  };
  return fields[item.field];
}
