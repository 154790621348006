import {
  TextField,
  Checkbox,
  Select,
  MenuItem,
  OutlinedInput,
  FormHelperText,
  Grid,
  InputAdornment,
  CircularProgress,
  FormControlLabel,
  IconButton,
  makeStyles,
  Typography,
  Box,
  InputLabel,
} from "@material-ui/core";
import VisibilitySharpIcon from "@material-ui/icons/VisibilitySharp";
import VisibilityOffSharpIcon from "@material-ui/icons/VisibilityOffSharp";
import InputBase from "@mui/material/InputBase";
import MuiPhoneNumber from "material-ui-phone-number";
import { alpha, styled } from "@mui/material/styles";
import React, { useState, useEffect, Fragment } from "react";
// import { RemoveSpace } from "../../../helper";
import FormControl from "@mui/material/FormControl";
const customstyle = {
  color: "var(--txt-f-l-clr) !important",
  marginTop: "0px !important",
  fontSize: "0.9rem",
  fontWeight: "350",
};
let phoneNumberStyle = {
  padding: "10px",
  fontSize: ".8125rem",
  fontWight: 400,
  lineHeight: 1.5,
  color: "var(--txt-f-clr)",
  backgroundColor: "transparent",
  border: "1px solid #e3ebf6",
  borderRadius: "0.25rem",
};
const useStyles = makeStyles((theme) => ({
  customstyle: { ...theme.customInputStyle },
  select: {
    marginTop: "0px",
  },
  input: {
    "&::placeholder": {
      color: "red",
    },
  },
  iconBackground: {
    "&:hover": {
      background: "white",
    },
  },
  textField: {
    color: "#0f4a7e",
  },
  adornmentIcon: {
    backgroundColor: "var(--accordion-expanded-bg)",
    borderRadius: "0.25rem",
    padding: "4px",
  },
  textFieldLabel: {
    color: "var(--txt-f-l-clr)",
    marginBottom: theme.spacing(1),
    fontSize: "13px",
    textAlign: "left",
    fontWeight: 600,
    fontFamily: "Roboto, sans-serif",
  },
  menuItem: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },

  placeholderStyle: {
    display: "block",
    width: "100%",
    fontSize: " .8125rem",
    fontWeight: 400,
    opacity: 0.4,
    lineHeight: 1.5,
    fontFamily: "Roboto, sans-serif",
    color: "var(--txt-f-placeholder)",
  },
  select: {
    "&:focus": {
      backgroundColor: "transparent", // Set the background color to transparent on focus
    },
  },
  selectMenu: {
    fontSize: "1rem",
    "&:hover": {
      color: "blue",
    },
  },
  selectIcon: {
    color: "var(--txt-f-clr)",
    fontSize: "1.8rem",
  },
  extra_Label: {
    color: "red",
  },
  selectInputsStyle: {
    display: "block",
    width: "100%",
    padding: "0 0.25rem 0 0.375rem",
    fontSize: ".8125rem",
    fontWight: 400,
    lineHeight: 1,
    color: "var(--txt-f-clr)",
    backgroundColor: "transparent",
    // backgroundColor: "#fff",
    border: "1px solid #e3ebf6",
    borderRadius: "0.25rem",
  },
  textFieldStyle: {
    width: "100%",
  },
  textFieldError: {
    color: "#F44336",
  },
  textInputStyle: (item) => {
    return {
      display: "block",
      width: "100%",
      // padding: "0.375rem 0.75rem",
      fontSize: " .8125rem",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#303e67",
      backgroundColor: item.disabled ? "#dddddd" : "transparent",
      backgroundClip: "padding-box",
      border: "1px solid #e3ebf6",
      appearance: "none",
      borderRadius: "0.25rem",
      transition: "border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out",
    };
  },
}));

const TextInput = ({ item, formik }) => {
  let { values, handleChange, errors, touched } = formik;
  let name = item.name;
  useEffect(() => {
    if (item.defaultValue) {
      formik.setFieldValue(item.name, item.defaultValue);
    }
    if (item?.reset !== undefined) {
      formik.setFieldValue(item.name, item?.reset ? item?.reset : "");
    }
  }, [item?.reset, item?.defaultValue]);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  let reqField = false;
  const handleCopyChange = (e) => {
    e.preventDefault();
  };
  if (item && item.validations) {
    item.validations.map((valid) => {
      if (valid.type === "required") {
        reqField = true;
      }
    });
  }

  const handleChangeEvent = (event) => {
    handleChange(event);
    item.callBackVal && item.callBackVal(event.target.value);
  };
  const handleEndAdornment = () => {
    if (item.endAdornment.onClick) {
      item.endAdornment.onClick(formik);
    }
  };

  const classes = useStyles();
  return (
    <Fragment>
      <FormControl variant="standard" fullWidth>
        <InputLabel className={classes.textFieldLabel}>{item.label}</InputLabel>
        <TextField
          inputProps={{
            maxLength: item.maxLength ? item.maxLength : null,
            readOnly: item.readOnly ? item.readOnly : false,
          }}
          InputProps={{
            disableUnderline: true,
            style: {
              width: "100%",
              padding: "0.5rem",
              fontSize: " 0.95rem",
              fontWeight: 500,
              lineHeight: 1.5,
              color: "var(--txt-f-clr)",
              backgroundColor: item.disabled ? "var(--txt-f-disabled)" : "",
              backgroundClip: "padding-box",
              border: "1px solid #C9D3DD",
              appearance: "none",
              borderRadius: "6px",
              transition:
                "border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out",
            },
            startAdornment: ["rate"].includes(item.name) ? (
              <Box display="flex" alignItems="center">
                <div
                  style={{
                    background: "#f0f0f0",
                    borderRight: "1px solid #ccc",
                    paddingRight: "8px",
                    marginRight: "8px",
                  }}
                >
                  <b>$</b>
                </div>
              </Box>
            ) : item.startAdornment ? (
              item.startAdornment
            ) : (
              ""
            ),
            maxLength: item.maxLength ? item.maxLength : null,
            readOnly: item.readOnly ? item.readOnly : false,
            endAdornment:
              item.type == "password" ? (
                <InputAdornment position="end">
                  <IconButton
                    id="view-password-btn"
                    aria-label="toggle password visibility"
                    className={classes.adornmentIcon}
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? (
                      <VisibilitySharpIcon fontSize="small" />
                    ) : (
                      <VisibilityOffSharpIcon fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ) : item.endAdornment ? (
                <InputAdornment position="end">
                  <span onClick={handleEndAdornment}>
                    {item.endAdornment.icon}
                  </span>
                </InputAdornment>
              ) : null,
          }}
          InputLabelProps={{
            style: customstyle,
          }}
          style={{
            backgroundColor: item.readOnly ? "var(--txt-f-disabled)" : "",
          }}
          type={showPassword ? "text" : item.type}
          min="0"
          onCut={item.copypaste && handleCopyChange}
          onCopy={item.copypaste && handleCopyChange}
          onPaste={item.copypaste && handleCopyChange}
          value={values[item.name]}
          name={item.name}
          disabled={item.disabled || false}
          onChange={handleChangeEvent}
          error={touched[name] && Boolean(errors[name])}
          fullWidth={true}
          helperText={
            item.helperText ? (
              <span style={{ color: "#808080" }}>{item.helperText}</span>
            ) : null
          }
        ></TextField>
      </FormControl>
      {errors[item.name] && touched[name] && (
        <FormHelperText className={classes.textFieldError}>
          {errors[item.name]}
        </FormHelperText>
      )}
      {item.html && item.html}
    </Fragment>
  );
};

const SelectInput = ({ item, formik }) => {
  const classes = useStyles();
  let { values, handleChange, errors, touched } = formik;
  let name = item.name;

  useEffect(() => {
    if (item.defaultValue && values[item.name] == "") {
      formik.setFieldValue(item.name, item.defaultValue.value);
    }
  }, []);
  const Placeholder = (children) => {
    return <div className={classes.placeholderStyle}>{children}</div>;
  };
  const handleSelectedValue = (val) => {
    let arr = [];
    item?.options?.map((opt) => {
      if (opt.value == val) {
        return arr.push(opt.name);
      }
    });
    return arr[0] || "";
  };

  const handleChangeEvent = (event) => {
    handleChange(event);
    item.callBackVal && item.callBackVal(event.target.value);
  };

  return (
    <Fragment>
      <InputLabel className={classes.textFieldLabel} id={item.label}>
        {item.label}
      </InputLabel>

      <Select
        disabled={item.disabled ? item.disabled : false}
        labelId={item.label}
        // style={customstyle}
        displayEmpty
        renderValue={(selected) => {
          if (selected?.length === 0) {
            return Placeholder(`Select ${item.label}`);
          }
          return handleSelectedValue(selected || "");
        }}
        onChange={handleChangeEvent}
        // id={`input-select-${RemoveSpace(item.name)}`}
        name={item.name}
        className={classes.selectInputsStyle}
        // InputProps={{
        //   disableUnderline: true,
        // }}
        style={
          item.disabled
            ? {
                backgroundColor: "var(--txt-f-disabled)",
                color: "var(--txt-f-clr)",
              }
            : {}
        }
        inputProps={{
          classes: {
            icon: classes.selectIcon,
          },
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          getContentAnchorEl: null,
        }}
        disableUnderline
        label={"dasdsadsada"}
        value={values[item.name]}
        fullWidth={true}
        error={touched[name] && Boolean(errors[name])}
      >
        {/* <MenuItem value="test" style={customstyle}>
          {item.label}
        </MenuItem> */}
        {item.options.map((opt) => (
          <MenuItem key={opt.value} value={opt.value}>
            {opt.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText className={classes.textFieldError}>
        {touched[name] && errors[name]}
      </FormHelperText>
    </Fragment>
  );
};
const PhoneNumber = ({ item, formik }) => {
  const classes = useStyles();
  let { values, errors, touched } = formik;
  return (
    <Fragment>
      <InputLabel className={classes.textFieldLabel} id={item.label}>
        {item.label}
      </InputLabel>
      <MuiPhoneNumber
        // style={{ width: "100%" }}
        fullWidth={true}
        placeholder={"Phone Number"}
        InputProps={{ disableUnderline: true, style: phoneNumberStyle }}
        defaultCountry={"us"}
        onlyCountries={["us"]}
        onChange={(val) => formik.setFieldValue(item.name, val)}
        value={values[item.name]}
        error={touched[item.name] && Boolean(errors[item.name])}
        helperText={touched[item.name] && errors[item.name]}
        name={item.name}
      />
    </Fragment>
  );
};
const CheckBox = ({ item, formik }) => {
  const { name } = item;
  const classes = useStyles();
  let { values, handleChange, errors, touched } = formik;
  useEffect(() => {
    if (item?.defaultValue) {
      formik.setFieldValue(item.name, item?.defaultValue);
    }
  }, [item?.defaultValue]);

  const handleCheckBox = (event) => {
    handleChange(event);
    item?.callBackVal && item?.callBackVal(event?.target?.checked);
  };
  // if (values[item.name]) {
  //   item?.checkValue && item?.checkValue(values[item.name]);
  // }
  return (
    <Fragment>
      <FormControlLabel
        style={customstyle}
        control={
          <Checkbox
            style={{ ...customstyle, color: "#08f" }}
            name={item.name}
            InputLabelProps={{
              style: customstyle,
            }}
            // color="#08f"
            checked={values[item.name]}
            onChange={handleCheckBox}
          />
        }
      />
      <Typography display="inline" style={customstyle}>
        {item.label}
        <span className={classes.extra_Label}>
          {item.extra_Label && item.extra_Label}
        </span>
      </Typography>
      {errors[item.name] && (
        <FormHelperText className={classes.textFieldError}>
          {(touched[name] && errors[name]) ||
            "Please Agree to terms and conditions"}
        </FormHelperText>
      )}
    </Fragment>
  );
};
// import React, { useState } from 'react';
// import Checkbox from '@mui/material/Checkbox';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Typography from '@mui/material/Typography';

// const TermsCheckbox = ({ item, formik }) => {
//   const [checked, setChecked] = useState(false);

//   const handleCheckboxChange = (event) => {
//     setChecked(event.target.checked);
//   };

//   return (
//     <FormControlLabel
//       control={
//         <Checkbox
//           checked={checked}
//           onChange={handleCheckboxChange}
//           color="primary"
//         />
//       }
//       label={
//         <Typography variant="body1">
//           {item?.text}
//         </Typography>
//       }
//       labelPlacement="end"
//     />
//   );
// };

const TextBox = ({ item, formik }) => {
  let { values, handleChange, errors, touched } = formik;
  let { name } = item;
  return (
    <Fragment>
      <Box
        style={{
          margin: "0px",
          padding: "0px",
          fontSize: "0.9rem",
          fontWeight: "300",
        }}
        component="span"
        // id={`input-${RemoveSpace(item.name)}`}
        sx={{
          display: "block",
          p: 1,
          m: 1,
          bgcolor: "background.paper",
        }}
      >
        {item.value || ""}
      </Box>
      <FormHelperText>{touched[name] && errors[name]}</FormHelperText>
    </Fragment>
  );
};

const TextArea = ({ item, formik }) => {
  let { values, handleChange, errors, touched } = formik;
  const classes = useStyles();
  let textAreaStyle = { ...phoneNumberStyle, padding: "10px" };
  let { name } = item;
  return (
    <Fragment>
      <InputLabel className={classes.textFieldLabel} id={item.label}>
        {item.label}
      </InputLabel>
      <TextField
        className={classes.textFieldStyle}
        name={item.name}
        // id={`input-${RemoveSpace(item.name)}`}
        InputLabelProps={{
          style: customstyle,
        }}
        InputProps={{
          disableUnderline: true,
          style: textAreaStyle,
          readOnly: item.readOnly ? item.readOnly : false,
        }}
        // inputProps={{
        //   readOnly: item.readOnly ? item.readOnly : false,
        // }}
        // label={item.label}
        multiline
        placeholder={
          item.placeholder ? item.placeholder : `Enter ${item.label}`
        }
        // variant="outlined"
        minRows={4}
        maxRows={6}
        value={values[item.name]}
        onChange={handleChange}
      ></TextField>
      <FormHelperText className={classes.textFieldError}>
        {touched[name] && errors[name]}
      </FormHelperText>
    </Fragment>
  );
};

const StyleSelectInput = ({ item, formik }) => {
  const classes = useStyles();
  useEffect(() => {
    if (item?.defaultValue) {
      formik.setFieldValue(item.name, item?.defaultValue);
    }
  }, [item?.defaultValue]);

  let { values, handleChange, errors, touched } = formik;
  let name = item.name;
  const handleSelectChange = (e) => {
    handleChange(e);
    item?.callBackVal && item?.callBackVal(e.target.value);
  };
  return (
    <Fragment>
      <FormControl variant="standard" fullWidth>
        <InputLabel className={classes.textFieldLabel}>{item.label}</InputLabel>
        <Select
          value={values[item.name]}
          onChange={handleSelectChange}
          disableUnderline
          classes={{ root: classes.select }}
          fullWidth
          disabled={item?.disabled}
          style={{
            width: "100%",
            padding: "0.5rem",
            fontSize: " 0.95rem",
            fontWeight: 500,
            lineHeight: 1.5,
            color: "var(--txt-f-clr)",
            backgroundColor: "#fff",
            backgroundClip: "padding-box",
            border: "1px solid #C9D3DD",
            appearance: "none",
            borderRadius: "6px",
            transition:
              "border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out",
          }}
          inputProps={{
            classes: {
              icon: classes.selectIcon,
            },
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            getContentAnchorEl: null,
          }}
          // id={`select-input-${RemoveSpace(item.name)}`}
          // style={{ paddingLeft: "31px", color: "#0f4a7e" }}
          name={item.name}
          error={touched[name] && Boolean(errors[name])}
        >
          {item.options.map((opt) => (
            <MenuItem
              classes={{ root: classes.selectMenu }}
              key={opt.id || opt.value}
              value={opt.id || opt.value}
            >
              {opt.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {errors[item.name] && touched[name] && (
        <FormHelperText className={classes.textFieldError}>
          {errors[name]}
        </FormHelperText>
      )}{" "}
    </Fragment>
  );
};

export {
  TextInput,
  SelectInput,
  TextArea,
  CheckBox,
  StyleSelectInput,
  TextBox,
  // PhoneInput,
  PhoneNumber,
};
