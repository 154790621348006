import React from "react";
import Container from "@mui/material/Container";
import { SvgContainer } from "../assets/svgContainer";
import { Typography } from "@material-ui/core";
const ThankYou = () => {
  return (
    <Container
      maxWidth="md"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        minHeight: "400px",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px", // Optional: Add rounded corners
        padding: "1rem", // Optional: Add padding
        backgroundColor: "#fff",
        textAlign: "center",
      }}
    >
      <div>
        <Typography variant="h4" gutterBottom style={{ fontFamily: "Roboto" }}>
          Thank You for Signing Up!
        </Typography>
        <SvgContainer item={"success"} size={150} />
        <p style={{ fontFamily: "Roboto" }}>
          We've sent you a confirmation email. Please check your email to
          complete your account registration.
        </p>
      </div>
    </Container>
  );
};

export default ThankYou;
