import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

const NotFoundPage = () => {
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        height: "fit-content",
        border: "1px solid #ccc", // Add border styling
        borderRadius: "8px", // Optional: Add rounded corners
        padding: "7rem", // Optional: Add padding
        backgroundColor: "#fff",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Page Not Found{" "}
      </Typography>
      <Typography variant="body1">
        The page you're looking for does not exist.
      </Typography>
    </Container>
  );
};

export { NotFoundPage };
