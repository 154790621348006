import React, { useState, useEffect } from "react";
import FormGenerator from "../../Components/FormGenerator";
import Container from "@mui/material/Container";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { uploadInsuranceDocument } from "../../Redux/Actions/loadDetails";
import { CircularProgress } from "@material-ui/core";
import { api } from "../../api";
import { useHistory } from "react-router-dom";

function Verify({ uploadInsuranceDocument }) {
  const { enqueueSnackbar } = useSnackbar();
  const { loadId } = useParams();
  const history = useHistory();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loader, setLoader] = useState(true);

  const verifyDocument = async () => {
    await api({
      url: `/verify_insurance_document/${loadId}`,
      method: "get",
    })
      .then((res) => {
        if (res?.data?.success) {
          setLoader(false);
        }
      })
      .catch((err) => {
        if (!err?.response?.data.success && err?.response?.data.message) {
          history.push("/login");
          enqueueSnackbar(
            err?.response?.data.message || "Something went wrong!",
            {
              variant: "error",
            }
          );
        }
      });
  };

  useEffect(() => {
    verifyDocument();
  }, []);

  const uploadFilesFields = [
    {
      label: "Enter code",
      name: "verification_code",
      field: "text",
      type: "text",
      placeholder: "Enter code",
      validationType: "string",
      validations: [
        {
          type: "required",
          params: ["this field is required"],
        },
      ],
      col: 12,
    },
    {
      name: "insurance_certificate_document",
      type: "text",
      label: "Upload File",
      field: "fileUpload",
      placeholder: "Choose File",
      validationType: "string",
      validations: [
        {
          type: "required",
          params: ["this field is required"],
        },
      ],
      col: 12,
    },
  ];

  const handleSubmit = async (values, formik, step, setDisable) => {
    setDisable(true);
    let dt = { ...values };
    let formData = new FormData();
    Object.keys(dt).forEach((item, ind) => {
      formData.append(item, dt[item]);
    });
    await uploadInsuranceDocument(
      formData,
      formik,
      enqueueSnackbar,
      setDisable,
      setIsSubmitted
    );
  };
  return (
    <div>
      <Container
        maxWidth="md"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          minHeight: "400px",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid #ccc", // Add border styling
          borderRadius: "8px", // Optional: Add rounded corners
          padding: "1rem", // Optional: Add padding
          backgroundColor: "#fff",
        }}
      >
        {loader ? (
          <CircularProgress
            style={{
              width: "25px",
              height: "25px",
              color: "#08f",
              "&:hover": {
                color: "#fff !important ",
              },
            }}
          />
        ) : isSubmitted ? (
          "Document has been uploaded successfully"
        ) : (
          <FormGenerator
            step={0}
            fields={uploadFilesFields}
            buttonText={"Submit"}
            onSubmit={handleSubmit}
          />
        )}
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { uploadInsuranceDocument })(Verify);
