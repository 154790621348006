// import defaultTheme from "./default";

// import { createMuiTheme } from "@material-ui/core";
import { createTheme } from "@material-ui/core";

// const overrides = {
//   typography: {
//     h1: {
//       fontSize: "3rem",
//     },
//     h2: {
//       fontSize: "2rem",
//     },
//     h3: {
//       fontSize: "1.64rem",
//     },
//     h4: {
//       fontSize: "1.5rem",
//     },
//     h5: {
//       fontSize: "1.285rem",
//     },
//     h6: {
//       fontSize: "1.142rem",
//     },
//   },
// };

// const themes = {
//   default: createMuiTheme({ ...defaultTheme, ...overrides }),
// };
const theme = createTheme({
  overrides: {
    MuiTableRow: {
      hover: {
        backgroundColor: "green",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "none",
      },
    },
    MuiInputBase: {
      root: {
        paddingRight: "0px !important",
      },
      hover: {
        boxShadow: "none",
      },
    },
    MuiButtonBase: {
      hover: {
        boxShadow: "none",
      },
    },
    MuiSelect: {
      select: {
        ":focus": {
          backgroundColor: "green", // Just for the demo
          border: "3px solid #A6CBF3",
        },
      },
    },
    MuiChip: {
      deleteIcon: {
        color: "var(--chip-icon-clr)",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#fff",
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      mmd: 1100,
      lg: 1280,
      lgr: 1600,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "Source Sans Pro",
    body1: {
      fontSize: "0.9rem",
      fontWeight: "350",
      fontFamily: "Source Sans Pro",
    },
  },

  input: {
    "MuiInputBase-input": {
      fontSize: "0.9rem",
      fontWeight: "350",
      color: "#0f4a7e",
    },
  },
  h3: {
    fontSize: "0.9rem",
    fontWeight: "350",
  },
  tableHeading: {
    font: "normal normal normal 28px/20px Source Sans Pro",
    fontFamily: "'Open Sans', sans-serif",
  },
  formHeading: {
    font: "normal normal normal 18px/3px  Source Sans Pro",
    letterSpacing: "0px",
    color: "#3E3E3E",
    opacity: "0.5",
    marginBottom: "16px",
    textAlign: "center",
  },
  customInputStyle: {
    color: "#0f4a7e",
    fontSize: "0.9rem",
    fontWeight: "350",
  },
  palette: {
    primary: {
      main: "#0f4a7e",
    },
    secondary: {
      main: "#ff0000",
    },
    background: {
      paper: "var(--nav-bg-clr)",
    },
  },
  logo: {
    width: "24%",
    marginTop: "2%",
    ["@media (max-width:780px)"]: {
      width: "65%",
      cursor: "pointer",
      marginTop: "4%",
      marginLeft: "0%",
    },
  },
  center: {
    textAlign: "center",
  },

  // Common Styling
  customhead: {
    color: "#3E3E3E",
    fontSize: "18px",
    fontFamily: "Source Sans Pro, sans-serif",
  },
  horizontalLine: {
    color: "#f4f4f4",
    opacity: "0.2",
  },
});

export { theme };
