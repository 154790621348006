const publicNavs = [
  // {
  //   name: "Tools",
  //   url: "/",
  // },
  // {
  //   name: "Services",
  //   url: "/",
  // },
  // {
  //   name: "Refrences",
  //   url: "/",
  // },

  {
    name: "Sign Up",
    url: "/signup",
    child: [
      {
        name: "Broker",
        url: "/signup/broker",
        preTxt: "Sign up as",
      },
      {
        name: "Shipper",
        url: "/signup/shipper",
        preTxt: "Sign up as",
      },
      {
        name: "Carrier",
        url: "/signup/carrier",
        preTxt: "Sign up as",
      },
    ],
  },
  {
    name: "Sign In",
    url: "/login",
  },
];

const privateNavs = [
  {
    name: "Loads Board",
    url: "/welcome",
  },
  {
    name: "Post a Load",
    url: "/post",
  },
  {
    name: "My Loads",
    url: "/loads",
  },
  // {
  //   name: "Sign Out",
  //   url: "/signout",
  // },
];
export { publicNavs, privateNavs };
