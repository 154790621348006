import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  success: {
    color: "#02824c !important",
    background: "#cdf7e5 !important",
    fontSize: "13px",
  },
  error: {
    color: "#931e37 !important",
    background: "#fdd6de !important",
    fontSize: "13px Roboto sans-serif !important",
    // borderLeft: "5px solid #931e37",
  },
  info: {
    color: "#931e37",
    // borderLeft: "5px solid rgb(2, 136, 209)",
    backgroundColor: "white !important",
    fontSize: "13px Roboto sans-serif !important",
  },
  iconStyle: {
    color: "#363636",
    width: "1em",
    height: "1em",
    // transform: "translateY(-25%)",
    position: "absolute",
  },

  menuItem: {
    padding: "6px 24px",
    fontWeight: 400,
    fontSize: "0.8125rem",
  },
  selectInputsStyle: {
    padding: "0px 5px 0px 5px",
    color: "#36A7D9 !important",
    fontSize: "0.9rem !important",
    // boxShadow:
    //   "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
    borderRadius: "6px",
    backgroundColor: "#fff",
    fontWeight: 350,
    marginLeft: "auto",
    fontFamily: "Poppins",
    // maxWidth: "100px",
  },
  selectInputsStyleMobile: {
    padding: "0px 5px 0px 5px",
    color: "#363636 !important",
    width: "240px",
    boxShadow:
      "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
    fontSize: "0.9rem !important",
    borderRadius: "6px",
    backgroundColor: "#fff",
    fontWeight: 350,
    fontFamily: "Poppins",
  },
  filterInputContainer: {
    // boxShadow:
    //   "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
    // padding: "7px 5px 5px 5px",
    // boxShadow: "none",
    // color: "#363636 !important",
    display: "flex",
    fontSize: "15px !important",
    borderRadius: "6px",
    backgroundColor: "#fff",
    fontWeight: 450,
    fontFamily: "Poppins",
    marginLeft: "auto",
  },
  filterInputContainerMobile: {
    display: "flex",
    fontSize: "15px !important",
    borderRadius: "6px",
    boxShadow:
      "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
    width: "240px",
    backgroundColor: "#fff",
    fontWeight: 450,
    fontFamily: "Poppins",
  },
  chipDataList: {
    listStyle: "none",
    display: "flex",
    marginBottom: 0,
    paddingLeft: 0,
    maxWidth: "240px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      height: "3px",
    },
  },
  filterInputsStyle: {
    padding: "0px 5px 0px 8px",
    color: "#363636 !important",
    fontSize: "15px !important",
    borderRadius: "6px",
    // border: "0.5px solid #707070",
    backgroundColor: "#fff",
    fontWeight: 350,
    fontFamily: "Poppins",
    maxWidth: (props) => (props?.extraSmallTOSmall ? "110px" : "110px"),
  },
  sizeAvatar: {
    width: "30px",
    height: "30px",
    display: "inline-flex",
    cursor: "pointer",
  },
  styleText: {
    letterSpacing: "0.02em",
    fontSize: "15px",
    fontWeight: "500",
    paddingRight: "5px",
    margin: "auto",
    color: "#2c3652",
    fontFamily: "Roboto",
  },
  pageTitelStyle: {
    fontSize: "17px",
    fontFamily: "'Poppins',Roboto",
    color: "#43425D",
    fontWeight: 450,
    textTransform: "capitalize",
    marginRight: "10px",
  },
  pageTitelStyleMobile: {
    fontSize: "15px",
    fontFamily: "'Poppins',Roboto",
    color: "#43425D",
    fontWeight: 450,
    textTransform: "capitalize",
    marginRight: "10px",
  },
  pageHeadingStyle: {
    fontSize: "17px",
    fontFamily: "'Poppins',Roboto",
    color: "#43425D",
    fontWeight: 450,
    textTransform: "capitalize",
  },
});

export { useStyles };
